import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Chip
} from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupIcon from '@mui/icons-material/Group';

function Broadcast() {
  const [selectedEvent, setSelectedEvent] = useState('');
  const [messageText, setMessageText] = useState('');
  const [messageType, setMessageType] = useState('all');

  const events = [
    { 
      id: 1, 
      name: 'Chamber Networking Breakfast', 
      date: '2024-03-15',
      attendees: 45,
      registered: 60
    },
    { 
      id: 2, 
      name: 'Business After Hours', 
      date: '2024-03-20',
      attendees: 75,
      registered: 100
    },
    { 
      id: 3, 
      name: 'Annual Chamber Gala', 
      date: '2024-04-01',
      attendees: 0,
      registered: 200
    }
  ];

  const handleBroadcast = () => {
    console.log({
      event: selectedEvent,
      messageType,
      message: messageText
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Chamber Check-In Broadcast Message
              </Typography>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Select Event</InputLabel>
                <Select
                  value={selectedEvent}
                  label="Select Event"
                  onChange={(e) => setSelectedEvent(e.target.value)}
                >
                  {events.map(event => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name} - {new Date(event.date).toLocaleDateString()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Recipient Group</InputLabel>
                <Select
                  value={messageType}
                  label="Recipient Group"
                  onChange={(e) => setMessageType(e.target.value)}
                >
                  <MenuItem value="all">All Registered Attendees</MenuItem>
                  <MenuItem value="checked-in">Checked-in Attendees Only</MenuItem>
                  <MenuItem value="not-checked-in">Not Checked-in Attendees</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                multiline
                rows={4}
                label="Broadcast Message"
                variant="outlined"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                sx={{ mt: 2 }}
              />

              <Button
                fullWidth
                variant="contained"
                startIcon={<CampaignIcon />}
                onClick={handleBroadcast}
                disabled={!selectedEvent || !messageText}
                sx={{ mt: 2 }}
              >
                Send Broadcast
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Broadcast Details
              </Typography>
              {selectedEvent && (
                <Box sx={{ mt: 2 }}>
                  {events.find(e => e.id === selectedEvent) && (
                    <>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle2" color="text.secondary">
                          Selected Event
                        </Typography>
                        <Typography variant="body1">
                          {events.find(e => e.id === selectedEvent).name}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <Chip
                          icon={<GroupIcon />}
                          label={`${events.find(e => e.id === selectedEvent).registered} Registered`}
                          color="primary"
                        />
                        <Chip
                          icon={<GroupIcon />}
                          label={`${events.find(e => e.id === selectedEvent).attendees} Checked In`}
                          color="success"
                        />
                      </Box>
                      <Alert severity="info">
                        Message will be sent to {messageType === 'all' ? 'all registered attendees' : 
                          messageType === 'checked-in' ? 'only checked-in attendees' : 
                          'attendees who haven\'t checked in'}
                      </Alert>
                    </>
                  )}
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Broadcast; 