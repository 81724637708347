import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Chip
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';

function SendMessage() {
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [messageText, setMessageText] = useState('');

  const events = [
    { id: 1, name: 'Chamber Networking Breakfast', date: '2024-03-15' },
    { id: 2, name: 'Business After Hours', date: '2024-03-20' },
    { id: 3, name: 'Annual Chamber Gala', date: '2024-04-01' }
  ];

  const attendees = [
    { id: 1, name: 'John Smith', phone: '(555) 123-4567' },
    { id: 2, name: 'Sarah Johnson', phone: '(555) 234-5678' },
    { id: 3, name: 'Mike Wilson', phone: '(555) 345-6789' }
  ];

  const handleSend = () => {
    console.log({
      event: selectedEvent,
      attendees: selectedAttendees,
      message: messageText
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h4" gutterBottom>
                Chamber Check-In Send Message
              </Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Select Event</InputLabel>
                <Select
                  value={selectedEvent}
                  label="Select Event"
                  onChange={(e) => setSelectedEvent(e.target.value)}
                >
                  {events.map(event => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name} - {new Date(event.date).toLocaleDateString()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Autocomplete
                multiple
                options={attendees}
                getOptionLabel={(option) => `${option.name} (${option.phone})`}
                value={selectedAttendees}
                onChange={(event, newValue) => setSelectedAttendees(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Recipients"
                    margin="normal"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      icon={<PersonIcon />}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />

              <TextField
                fullWidth
                multiline
                rows={4}
                label="Message"
                variant="outlined"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                sx={{ mt: 2 }}
              />

              <Button
                fullWidth
                variant="contained"
                startIcon={<SendIcon />}
                onClick={handleSend}
                disabled={!selectedAttendees.length || !messageText}
                sx={{ mt: 2 }}
              >
                Send Message
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Message Preview
              </Typography>
              <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  To: {selectedAttendees.map(a => a.name).join(', ')}
                </Typography>
                <Typography variant="body1">
                  {messageText || 'Your message will appear here...'}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SendMessage; 