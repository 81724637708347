import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MessageIcon from '@mui/icons-material/Message';
import GroupIcon from '@mui/icons-material/Group';

function Messages() {
  const [selectedEvent, setSelectedEvent] = useState('');
  const [messageText, setMessageText] = useState('');

  const recentMessages = [
    {
      event: 'Chamber Networking Breakfast',
      message: 'Reminder: Tomorrow\'s networking breakfast starts at 8:30 AM',
      sent: '2 hours ago',
      recipients: 45
    },
    {
      event: 'Business After Hours',
      message: 'Thank you for attending! Please fill out our feedback survey.',
      sent: '1 day ago',
      recipients: 75
    },
    {
      event: 'Annual Chamber Gala',
      message: 'Early bird registration is now open!',
      sent: '3 days ago',
      recipients: 200
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                <SendIcon sx={{ mr: 1 }} />
                Send Message
              </Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Select Event</InputLabel>
                <Select
                  value={selectedEvent}
                  label="Select Event"
                  onChange={(e) => setSelectedEvent(e.target.value)}
                >
                  <MenuItem value="breakfast">Chamber Networking Breakfast</MenuItem>
                  <MenuItem value="afterhours">Business After Hours</MenuItem>
                  <MenuItem value="gala">Annual Chamber Gala</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Message"
                variant="outlined"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Button
                fullWidth
                variant="contained"
                startIcon={<SendIcon />}
                sx={{ mt: 2 }}
              >
                Send Message
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recent Messages
                </Typography>
                <List>
                  {recentMessages.map((msg, index) => (
                    <ListItem
                      key={index}
                      divider={index !== recentMessages.length - 1}
                      sx={{ py: 2 }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <MessageIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={msg.event}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body2">
                              {msg.message}
                            </Typography>
                            <br />
                            Sent {msg.sent}
                          </React.Fragment>
                        }
                      />
                      <Chip
                        icon={<GroupIcon />}
                        label={`${msg.recipients} recipients`}
                        size="small"
                        color="primary"
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Messages; 