import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import QrCodeIcon from '@mui/icons-material/QrCode';
import reportWebVitals from './reportWebVitals';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventIcon from '@mui/icons-material/Event';
import Events from './components/Events';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MessageIcon from '@mui/icons-material/Message';
import GroupIcon from '@mui/icons-material/Group';
import CheckIn from './components/CheckIn';
import Messages from './components/Messages';
import Attendees from './components/Attendees';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import CampaignIcon from '@mui/icons-material/Campaign';
import HistoryIcon from '@mui/icons-material/History';
import SendMessage from './components/messages/SendMessage';
import Broadcast from './components/messages/Broadcast';
import MessageHistory from './components/messages/MessageHistory';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import QrCode from './components/QrCode';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QRScanner from './components/check-in/QRScanner';
import NewRegistration from './components/check-in/NewRegistration';
import QRCodeManager from './components/qr/QRCodeManager';
import { orange } from '@mui/material/colors';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneValidator from './components/validators/PhoneValidator';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    flexGrow: 1,
    paddingTop: theme.spacing(3),
  }),
);

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  color: theme.palette.primary.main,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTypography-root': {
    fontSize: '1.1rem',
    fontWeight: 500
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.8rem',
    marginRight: theme.spacing(1)
  }
}));

const SidebarButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: 42,
  padding: '4px 16px',
  '& .MuiListItemIcon-root': {
    minWidth: 36,
    '& svg': {
      fontSize: '1.3rem',
    }
  },
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontSize: '0.875rem',
    }
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  }
}));

const SidebarSubButton = styled(ListItemButton)(({ theme }) => ({
  minHeight: 36,
  padding: '2px 16px 2px 48px',
  '& .MuiListItemIcon-root': {
    minWidth: 36,
    '& svg': {
      fontSize: '1.2rem',
    }
  },
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontSize: '0.815rem',
    }
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: orange[500],
      light: orange[300],
      dark: orange[700],
    },
  },
});

function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState('Dashboard');
  const [messagesOpen, setMessagesOpen] = useState(() => {
    return localStorage.getItem('messagesOpen') === 'true';
  });
  const [checkInOpen, setCheckInOpen] = useState(() => {
    return localStorage.getItem('checkInOpen') === 'true';
  });
  const [qrOpen, setQrOpen] = useState(() => {
    return localStorage.getItem('qrOpen') === 'true';
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    handleMenuClose();
    navigate('/profile');
  };

  const handleLogout = () => {
    console.log('Logout clicked');
    handleMenuClose();
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleMessagesClick = () => {
    const newState = !messagesOpen;
    setMessagesOpen(newState);
    localStorage.setItem('messagesOpen', newState);
  };

  const handleCheckInClick = () => {
    const newState = !checkInOpen;
    setCheckInOpen(newState);
    localStorage.setItem('checkInOpen', newState);
  };

  const handleQrClick = () => {
    const newState = !qrOpen;
    setQrOpen(newState);
    localStorage.setItem('qrOpen', newState);
  };

  useEffect(() => {
    const updatePageTitle = () => {
      const path = window.location.pathname;
      switch(path) {
        case '/':
          setPageTitle('Dashboard');
          break;
        case '/events':
          setPageTitle('Events');
          break;
        case '/qrcode':
          setPageTitle('QR Code Generator');
          break;
        case '/profile':
          setPageTitle('Profile');
          break;
        case '/check-in':
          setPageTitle('Check In');
          break;
        case '/messages':
          setPageTitle('Messages');
          break;
        case '/messages/send':
          setPageTitle('Send Message');
          break;
        case '/messages/broadcast':
          setPageTitle('Broadcast Message');
          break;
        case '/messages/history':
          setPageTitle('Message History');
          break;
        case '/attendees':
          setPageTitle('Attendees');
          break;
        default:
          setPageTitle('Dashboard');
      }
    };

    updatePageTitle();
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith('/check-in')) {
      setCheckInOpen(true);
      localStorage.setItem('checkInOpen', 'true');
    }
    if (path.startsWith('/messages')) {
      setMessagesOpen(true);
      localStorage.setItem('messagesOpen', 'true');
    }
    if (path.startsWith('/qr')) {
      setQrOpen(true);
      localStorage.setItem('qrOpen', 'true');
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ 
            width: `calc(100% - ${drawerWidth}px)`, 
            ml: `${drawerWidth}px`,
            bgcolor: '#205080'
          }}
        >
          <Toolbar sx={{ 
            display: 'flex', 
            justifyContent: 'space-between'
          }}>
            <Typography 
              variant="h6" 
              noWrap 
              component="div"
              sx={{ color: '#ffffff' }}
            >
              {pageTitle}
            </Typography>
            <IconButton
              color="inherit"
              aria-label="user settings"
              edge="end"
              onClick={handleMenuClick}
              aria-controls={open ? 'user-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <PersonIcon sx={{ fontSize: 35, color: '#ffffff' }} />
            </IconButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'user-button',
              }}
            >
              <MenuItem onClick={handleProfile}>My Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <LogoContainer
            onClick={handleLogoClick}
            sx={{ cursor: 'pointer' }}
          >
            <AssignmentIcon sx={{ fontSize: 40, mr: 1 }} />
            <Typography variant="h6" noWrap>
              Chamber Check-In
            </Typography>
          </LogoContainer>
          <Divider />
          <List>
            <ListItem disablePadding>
              <SidebarButton to="/">
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primary="Dashboard" />
              </SidebarButton>
            </ListItem>
            <ListItem disablePadding>
              <SidebarButton to="/events">
                <ListItemIcon><EventIcon /></ListItemIcon>
                <ListItemText primary="Events" />
              </SidebarButton>
            </ListItem>
            <ListItem disablePadding>
              <SidebarButton to="/qrcode">
                <ListItemIcon><QrCodeIcon /></ListItemIcon>
                <ListItemText primary="QR Codes" />
                {qrOpen ? <ExpandLess /> : <ExpandMore />}
              </SidebarButton>
            </ListItem>
            <Collapse in={qrOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/qr/generate">
                    <ListItemIcon><QrCodeIcon /></ListItemIcon>
                    <ListItemText primary="Generate QR" />
                  </SidebarButton>
                </ListItem>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/qr/manage">
                    <ListItemIcon><AssignmentIcon /></ListItemIcon>
                    <ListItemText primary="Manage QR Codes" />
                  </SidebarButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem disablePadding>
              <SidebarButton onClick={handleCheckInClick}>
                <ListItemIcon><CheckCircleIcon /></ListItemIcon>
                <ListItemText primary="Check-in" />
                {checkInOpen ? <ExpandLess /> : <ExpandMore />}
              </SidebarButton>
            </ListItem>
            <Collapse in={checkInOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/check-in/scan">
                    <ListItemIcon><QrCodeScannerIcon /></ListItemIcon>
                    <ListItemText primary="Scan QR" />
                  </SidebarButton>
                </ListItem>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/check-in/manual">
                    <ListItemIcon><CheckCircleIcon /></ListItemIcon>
                    <ListItemText primary="Manual Check-in" />
                  </SidebarButton>
                </ListItem>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/check-in/register">
                    <ListItemIcon><PersonAddIcon /></ListItemIcon>
                    <ListItemText primary="New Registration" />
                  </SidebarButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem disablePadding>
              <SidebarButton to="/attendees">
                <ListItemIcon><GroupIcon /></ListItemIcon>
                <ListItemText primary="Attendees" />
              </SidebarButton>
            </ListItem>
            <ListItem disablePadding>
              <SidebarButton onClick={handleMessagesClick}>
                <ListItemIcon><MessageIcon /></ListItemIcon>
                <ListItemText primary="Messages" />
                {messagesOpen ? <ExpandLess /> : <ExpandMore />}
              </SidebarButton>
            </ListItem>
            <Collapse in={messagesOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/messages">
                    <ListItemIcon><MessageIcon /></ListItemIcon>
                    <ListItemText primary="All Messages" />
                  </SidebarButton>
                </ListItem>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/messages/send">
                    <ListItemIcon><ForwardToInboxIcon /></ListItemIcon>
                    <ListItemText primary="Send Message" />
                  </SidebarButton>
                </ListItem>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/messages/broadcast">
                    <ListItemIcon><CampaignIcon /></ListItemIcon>
                    <ListItemText primary="Broadcast" />
                  </SidebarButton>
                </ListItem>
                <ListItem disablePadding sx={{ pl: 4 }}>
                  <SidebarButton to="/messages/history">
                    <ListItemIcon><HistoryIcon /></ListItemIcon>
                    <ListItemText primary="Message History" />
                  </SidebarButton>
                </ListItem>
              </List>
            </Collapse>
            <ListItem disablePadding>
              <SidebarButton to="/phone-validator">
                <ListItemIcon><PhoneIcon /></ListItemIcon>
                <ListItemText primary="Phone Validator" />
              </SidebarButton>
            </ListItem>
          </List>
        </Drawer>
        <Main>
          <Toolbar />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/events" element={<Events />} />
            <Route path="/check-in/manual" element={<CheckIn />} />
            <Route path="/check-in/scan" element={<QRScanner />} />
            <Route path="/check-in/register" element={<NewRegistration />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/messages/send" element={<SendMessage />} />
            <Route path="/messages/broadcast" element={<Broadcast />} />
            <Route path="/messages/history" element={<MessageHistory />} />
            <Route path="/qr/generate" element={<QrCode />} />
            <Route path="/qr/manage" element={<QRCodeManager />} />
            <Route path="/attendees" element={<Attendees />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/phone-validator" element={<PhoneValidator />} />
          </Routes>
        </Main>
      </Box>
    </ThemeProvider>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;

reportWebVitals();
