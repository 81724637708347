const API_URL = process.env.REACT_APP_API_URL || '/api';

export const generateQRCode = async (text, type) => {
  console.log('Sending QR code generation request:', { text, type });
  
  try {
    // Ensure we have /api in the path
    const url = `${window.location.origin}/api/generate-qr`;
    console.log('Making request to:', url);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text, type }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Server returned error:', errorData);
      throw new Error(errorData.message || 'Failed to generate QR code');
    }

    const data = await response.json();
    console.log('QR code generation response:', data);
    return data;
  } catch (error) {
    console.error('Error in generateQRCode:', error);
    throw error;
  }
};

// Add events API function
export const createEvent = async (eventData) => {
  try {
    const url = `${window.location.origin}/api/events`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to create event');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
}; 