import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
  Box
} from '@mui/material';
import SmsIcon from '@mui/icons-material/Sms';
import LanguageIcon from '@mui/icons-material/Language';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DownloadIcon from '@mui/icons-material/Download';
import { generateQRCode } from '../services/api';

function QrCode() {
  const [inputText, setInputText] = useState('');
  const [qrType, setQrType] = useState('sms');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [filename, setFilename] = useState('');

  const validatePhoneNumber = (phone) => {
    const cleaned = phone.replace(/\D/g, '');
    
    if (!cleaned) {
      return { isValid: false, error: 'Phone number is required' };
    }
    
    if (cleaned.length !== 10) {
      return { isValid: false, error: 'Phone number must be 10 digits' };
    }
    
    return { isValid: true, formatted: cleaned };
  };

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
    
    if (!value) {
      setError(null);
      return;
    }
    
    const validation = validatePhoneNumber(value);
    if (!validation.isValid) {
      setError(validation.error);
    } else {
      setError(null);
      setPhoneNumber(validation.formatted);
    }
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleTypeChange = (type) => {
    setQrType(type);
    setQrCode(null);
    if (type === 'url') {
      setPhoneNumber('');
      setMessage('');
      setInputText('');
    } else {
      setInputText('');
    }
  };

  const handleGenerateQR = async () => {
    if (!inputText) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const response = await generateQRCode(inputText, qrType);
      if (response.success) {
        setQrCode(response.qrCode);
      } else {
        setError(response.message);
      }
    } catch (err) {
      setError('Failed to generate QR code');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (qrType === 'sms') {
      setInputText(`SMSTO:${phoneNumber}:${message}`);
    }
  }, [qrType, phoneNumber, message]);

  return (
    <Grid container spacing={2} sx={{ justifyContent: 'flex-start', p: 3 }}>
      <Grid item xs={12} md={4}>
        <Card sx={{ width: '100%', mb: 2 }}>
          <CardContent>
            <Box sx={{ mb: 2 }}>
              <Button 
                variant="contained"
                sx={{ mr: 2 }}
                startIcon={<SmsIcon />}
                onClick={() => handleTypeChange('sms')}
                color={qrType === 'sms' ? 'primary' : 'inherit'}
              >
                SMS
              </Button>
              <Button 
                variant="contained"
                startIcon={<LanguageIcon />}
                onClick={() => handleTypeChange('url')}
                color={qrType === 'url' ? 'primary' : 'inherit'}
              >
                URL
              </Button>
            </Box>
            {qrType === 'sms' && (
              <Box sx={{ mb: 2, mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  SMS QR Code
                </Typography>
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                  disabled={loading}
                  sx={{ mb: 2 }}
                  error={Boolean(error)}
                  helperText={error || 'Format: 1234567890'}
                  placeholder="Enter 10 digit number"
                />
                <TextField
                  fullWidth
                  label="Message"
                  variant="outlined"
                  value={message}
                  onChange={handleMessageChange}
                  disabled={loading}
                  multiline
                  rows={2}
                />
              </Box>
            )}
            <TextField
              fullWidth
              label={qrType === 'url' ? "Enter URL" : "Preview"}
              variant="outlined"
              value={inputText}
              onChange={handleInputChange}
              disabled={loading || qrType === 'sms'}
            />
            <TextField
              fullWidth
              label="Filename"
              variant="outlined"
              value={filename}
              onChange={(e) => setFilename(e.target.value)}
              sx={{ mt: 2, mb: 2 }}
              placeholder="Enter filename (optional)"
              helperText="File will be saved as filename.png or filename.jpg"
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 2 }}
              onClick={handleGenerateQR}
              disabled={!inputText || loading}
              startIcon={<QrCodeIcon />}
            >
              Generate QR Code
            </Button>
            {error && (
              <Typography color="error" sx={{ mt: 1 }}>
                {error}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card sx={{ minWidth: 275, mb: 2 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              QR Code
            </Typography>
            {qrCode ? (
              <Box sx={{ textAlign: 'center' }}>
                <img 
                  src={qrCode} 
                  alt="Generated QR Code"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </Box>
            ) : (
              <Typography variant="body1" color="text.secondary">
                Generated QR code will appear here
              </Typography>
            )}
          </CardContent>
          {qrCode && (
            <CardActions sx={{ justifyContent: 'flex-end', gap: 1 }}>
              <Button 
                variant="contained"
                startIcon={<DownloadIcon />}
                download={`${filename || 'phone_number_message'}.png`}
                href={qrCode}
                sx={{ 
                  minWidth: '150px',
                  '& .MuiButton-startIcon': {
                    position: 'absolute',
                    left: '8px'
                  },
                  '& .MuiButton-startIcon + span': {
                    marginLeft: '24px'
                  }
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Typography 
                    variant="button"
                    sx={{ 
                      fontSize: '0.95rem',
                      fontWeight: 'bold'
                    }}
                  >
                    Download PNG
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ mt: 0.5 }}
                    color="inherit"
                  >
                    {filename ? `${filename}.png` : 'phone_number_message.png'}
                  </Typography>
                </Box>
              </Button>
              <Button 
                variant="outlined"
                startIcon={<DownloadIcon />}
                download={`${filename || 'phone_number_message'}.jpg`}
                href={qrCode.replace('image/png', 'image/jpeg')}
                sx={{ 
                  minWidth: '150px',
                  '& .MuiButton-startIcon': {
                    position: 'absolute',
                    left: '8px'
                  },
                  '& .MuiButton-startIcon + span': {
                    marginLeft: '24px'
                  }
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                  <Typography 
                    variant="button"
                    sx={{ 
                      fontSize: '0.95rem',
                      fontWeight: 'bold'
                    }}
                  >
                    Download JPG
                  </Typography>
                  <Typography 
                    variant="caption" 
                    sx={{ mt: 0.5 }}
                    color="inherit"
                  >
                    {filename ? `${filename}.jpg` : 'phone_number_message.jpg'}
                  </Typography>
                </Box>
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

export default QrCode; 