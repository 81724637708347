import React, { useState, useCallback } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  IconButton,
  Tooltip,
  TablePagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { format, parse } from 'date-fns';

function PhoneValidator() {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [validating, setValidating] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState('clubessential');

  const validatePhoneNumber = async (phone) => {
    if (!phone) return { isValid: false, error: 'Empty number' };
    
    try {
      const response = await axios.post('/api/validate-phone', { 
        phoneNumber: phone 
      });
      
      console.log('Telnyx response:', response.data.info);
      
      return {
        isValid: response.data.isValid,
        error: response.data.error,
        info: response.data.info
      };
    } catch (err) {
      console.error('Validation error:', err);
      return { isValid: false, error: 'Validation failed' };
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    const isValidPhoneFormat = (phone) => {
      if (!phone) return false;
      const cleaned = phone.toString().replace(/\D/g, '');
      return cleaned.length === 10 || (cleaned.length === 11 && cleaned[0] === '1');
    };

    const isRowEmpty = (row) => {
      if (!row) return true;
      return row.every(cell => cell === undefined || cell === null || cell === '');
    };

    reader.onload = async (e) => {
      try {
        setValidating(true);
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        // Filter out empty rows and process only non-empty ones
        const nonEmptyRows = jsonData.slice(1).filter(row => !isRowEmpty(row));

        const processedData = [];
        for (const [index, row] of nonEmptyRows.entries()) {
          // Get data based on selected format
          const phoneNumber = selectedFormat === 'clubessential' ? row[7] : row[0];
          const firstName = selectedFormat === 'clubessential' ? row[2] : row[1];
          const lastName = selectedFormat === 'clubessential' ? row[1] : row[2];
          
          // Additional fields for clubessential format
          const memberNumber = selectedFormat === 'clubessential' ? row[0] : null;
          const gender = selectedFormat === 'clubessential' ? row[3] : null;
          const joinDate = selectedFormat === 'clubessential' ? row[4] : null;
          const birthdate = selectedFormat === 'clubessential' ? row[5] : null;
          const age = selectedFormat === 'clubessential' ? row[6] : null;
          const email = selectedFormat === 'clubessential' ? row[8] : null;
          const memberType = selectedFormat === 'clubessential' ? row[9] : null;

          if (phoneNumber && isValidPhoneFormat(phoneNumber)) {
            const validation = await validatePhoneNumber(phoneNumber);
            
            processedData.push({
              id: index + 1,
              rowNumber: index + 2,
              memberNumber,
              lastName,
              firstName,
              gender,
              joinDate,
              birthdate,
              age,
              phoneNumber,
              email,
              memberType,
              cleaned: validation.isValid && isValidPhoneFormat(phoneNumber) ? validation.info?.phone_number : null,
              isValid: validation.isValid && isValidPhoneFormat(phoneNumber),
              error: !isValidPhoneFormat(phoneNumber) ? 
                     'Invalid phone number format' : 
                     validation.error,
              carrierInfo: validation.info
            });
          } else {
            processedData.push({
              id: index + 1,
              rowNumber: index + 2,
              memberNumber,
              lastName,
              firstName,
              gender,
              joinDate,
              birthdate,
              age,
              phoneNumber,
              email,
              memberType,
              cleaned: null,
              isValid: false,
              error: !phoneNumber ? 'Missing phone number' : 
                     'Invalid phone number format',
              carrierInfo: null
            });
          }
        }

        setPhoneNumbers(processedData);
        setError(null);
      } catch (err) {
        setError('Error processing file. Please ensure it\'s a valid Excel file.');
        console.error('File processing error:', err);
      } finally {
        setValidating(false);
      }
    };

    reader.readAsArrayBuffer(file);
  }, [selectedFormat]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    multiple: false
  });

  const formatExcelDate = (date) => {
    if (!date) return '-';
    try {
      // Check if it's an Excel date number
      if (typeof date === 'number') {
        // Convert Excel date number to JS date
        // Excel dates are number of days since Dec 30, 1899
        const excelEpoch = new Date(1899, 11, 30);
        const offsetMillis = date * 24 * 60 * 60 * 1000;
        const dateObj = new Date(excelEpoch.getTime() + offsetMillis);
        return format(dateObj, 'MM/dd/yyyy');
      }
      
      // If it's already a string in date format, parse it
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate.getTime())) {
        return format(parsedDate, 'MM/dd/yyyy');
      }
      
      return date; // Return original if parsing fails
    } catch (error) {
      return date; // Return original if formatting fails
    }
  };

  const formatBirthdate = (date) => formatExcelDate(date);
  const formatDate = (date) => formatExcelDate(date);

  const cleanPhoneNumber = (phone) => {
    if (!phone) return '';
    return phone.toString().replace(/\D/g, '');
  };

  const handleDownload = () => {
    const validNumbers = phoneNumbers.filter(p => p.isValid);
    
    const ws = XLSX.utils.json_to_sheet(validNumbers.map(p => {
      if (selectedFormat === 'clubessential') {
        return {
          'Phone Number': cleanPhoneNumber(p.phoneNumber),
          'First Name': p.firstName,        
          'Last Name': p.lastName,          
          'Email': p.email,                 
          'Birthdate': formatBirthdate(p.birthdate), 
          'Join Date': formatDate(p.joinDate),
          'Member Number': p.memberNumber,
          'Age': p.age,
          'Gender': p.gender,
          'Member Type': p.memberType,
          'Mobile': p.carrierInfo?.portability?.line_type === 'mobile' ? 'Yes' : 'No'
        };
      } else {
        return {
          'Phone Number': cleanPhoneNumber(p.phoneNumber),
          'First Name': p.firstName,
          'Last Name': p.lastName,
          'Mobile': p.carrierInfo?.portability?.line_type === 'mobile' ? 'Yes' : 'No'
        };
      }
    }));
    
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Validated Numbers');
    
    XLSX.writeFile(wb, 'validated_phone_numbers.xlsx');
  };

  const handleDownloadInvalid = () => {
    const invalidNumbers = phoneNumbers.filter(p => {
      if (!p.phoneNumber) return true;
      if (typeof p.phoneNumber === 'string' && p.phoneNumber.trim() === '') return true;
      return !p.isValid;
    });
    
    const ws = XLSX.utils.json_to_sheet(invalidNumbers.map(p => {
      if (selectedFormat === 'clubessential') {
        return {
          'Phone Number': cleanPhoneNumber(p.phoneNumber),
          'First Name': p.firstName,        
          'Last Name': p.lastName,          
          'Email': p.email,                 
          'Birthdate': formatBirthdate(p.birthdate), 
          'Join Date': formatDate(p.joinDate),
          'Member Number': p.memberNumber,
          'Age': p.age,
          'Gender': p.gender,
          'Member Type': p.memberType,
          'Mobile': p.isValid ? (p.carrierInfo?.portability?.line_type === 'mobile' ? 'Yes' : 'No') : '-',
          'Reason': !p.phoneNumber ? 'Missing phone number' : 
                   (typeof p.phoneNumber === 'string' && p.phoneNumber.trim() === '') ? 'Empty phone number' : 
                   p.error
        };
      } else {
        return {
          'Phone Number': cleanPhoneNumber(p.phoneNumber),
          'First Name': p.firstName,
          'Last Name': p.lastName,
          'Mobile': p.isValid ? (p.carrierInfo?.portability?.line_type === 'mobile' ? 'Yes' : 'No') : '-',
          'Reason': !p.phoneNumber ? 'Missing phone number' : 
                   (typeof p.phoneNumber === 'string' && p.phoneNumber.trim() === '') ? 'Empty phone number' : 
                   p.error
        };
      }
    }));
    
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Invalid Numbers');
    
    XLSX.writeFile(wb, 'invalid_phone_numbers.xlsx');
  };

  const handleClear = () => {
    setPhoneNumbers([]);
    setError(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Phone Number Validator
      </Typography>

      <FormControl 
        sx={{ 
          minWidth: 200, 
          mb: 3 
        }}
      >
        <InputLabel>File Format</InputLabel>
        <Select
          value={selectedFormat}
          label="File Format"
          onChange={(e) => setSelectedFormat(e.target.value)}
        >
          <MenuItem value="clubessential">Clubessential</MenuItem>
          <MenuItem value="standard">Standard</MenuItem>
        </Select>
      </FormControl>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed',
              borderColor: isDragActive ? 'primary.main' : 'grey.300',
              borderRadius: 1,
              p: 3,
              textAlign: 'center',
              cursor: 'pointer',
              mb: 2
            }}
          >
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 1 }} />
            <Typography variant="h6" gutterBottom>
              {isDragActive ? 'Drop the file here' : 'Drag & drop an Excel file here'}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              or click to select a file
            </Typography>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {validating && (
            <Alert severity="info" sx={{ mb: 2 }}>
              Validating phone numbers... This may take a few moments.
            </Alert>
          )}

          {phoneNumbers.length > 0 && (
            <Box sx={{ mb: 2 }}>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={handleDownload}
                sx={{ mr: 1 }}
              >
                Download All Results
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<DownloadIcon />}
                onClick={handleDownloadInvalid}
                sx={{ mr: 1 }}
                disabled={!phoneNumbers.some(p => {
                  if (!p.phoneNumber) return true;
                  if (typeof p.phoneNumber === 'string' && p.phoneNumber.trim() === '') return true;
                  return !p.isValid;
                })}
              >
                Download Invalid Numbers
              </Button>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleClear}
              >
                Clear
              </Button>
            </Box>
          )}

          {phoneNumbers.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Row</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      {selectedFormat === 'clubessential' && (
                        <>
                          <TableCell>Email</TableCell>
                          <TableCell>Birthdate</TableCell>
                        </>
                      )}
                      <TableCell>Status</TableCell>
                      <TableCell>Mobile</TableCell>
                      <TableCell>Error</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {phoneNumbers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((phone) => (
                        <TableRow key={phone.id}>
                          <TableCell>{phone.rowNumber}</TableCell>
                          <TableCell>{phone.phoneNumber}</TableCell>
                          <TableCell>{phone.firstName}</TableCell>
                          <TableCell>{phone.lastName}</TableCell>
                          {selectedFormat === 'clubessential' && (
                            <>
                              <TableCell>{phone.email}</TableCell>
                              <TableCell>{formatBirthdate(phone.birthdate)}</TableCell>
                            </>
                          )}
                          <TableCell>
                            <Tooltip title={phone.isValid ? 'Valid' : 'Invalid'}>
                              <IconButton size="small" color={phone.isValid ? 'success' : 'error'}>
                                {phone.isValid ? <CheckCircleIcon /> : <ErrorIcon />}
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            {phone.isValid ? (phone.carrierInfo?.portability?.line_type === 'mobile' ? 'Yes' : 'No') : '-'}
                          </TableCell>
                          <TableCell>{phone.error || '-'}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={phoneNumbers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </CardContent>
      </Card>

      {phoneNumbers.length > 0 && (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Summary
            </Typography>
            <Typography>
              Total Numbers: {phoneNumbers.length}
            </Typography>
            <Typography>
              Valid Numbers: {phoneNumbers.filter(p => p.isValid).length}
            </Typography>
            <Typography>
              Invalid Numbers: {phoneNumbers.filter(p => !p.isValid).length}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}

export default PhoneValidator; 