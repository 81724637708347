import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

function NewRegistration() {
  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>
          New Registration
        </Typography>
        <Typography>
          New registration form will be implemented here
        </Typography>
      </Paper>
    </Box>
  );
}

export default NewRegistration; 