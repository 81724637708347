import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import GroupIcon from '@mui/icons-material/Group';

function MessageHistory() {
  const [timeFilter, setTimeFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');

  const messages = [
    {
      id: 1,
      type: 'broadcast',
      event: 'Chamber Networking Breakfast',
      message: 'Reminder: Tomorrow\'s networking breakfast starts at 8:30 AM',
      sent: '2024-03-14 14:30',
      recipients: 45,
      delivered: 43,
      failed: 2
    },
    {
      id: 2,
      type: 'individual',
      event: 'Business After Hours',
      message: 'Your registration has been confirmed',
      sent: '2024-03-13 10:15',
      recipients: 1,
      delivered: 1,
      failed: 0
    },
    {
      id: 3,
      type: 'broadcast',
      event: 'Annual Chamber Gala',
      message: 'Early bird registration closing soon!',
      sent: '2024-03-12 09:00',
      recipients: 200,
      delivered: 195,
      failed: 5
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6">Chamber Check-In Message History</Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel>Time Period</InputLabel>
                    <Select
                      value={timeFilter}
                      label="Time Period"
                      onChange={(e) => setTimeFilter(e.target.value)}
                    >
                      <MenuItem value="all">All Time</MenuItem>
                      <MenuItem value="today">Today</MenuItem>
                      <MenuItem value="week">This Week</MenuItem>
                      <MenuItem value="month">This Month</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel>Message Type</InputLabel>
                    <Select
                      value={typeFilter}
                      label="Message Type"
                      onChange={(e) => setTypeFilter(e.target.value)}
                    >
                      <MenuItem value="all">All Types</MenuItem>
                      <MenuItem value="broadcast">Broadcast</MenuItem>
                      <MenuItem value="individual">Individual</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip title="Refresh">
                    <IconButton>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Event</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Sent</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {messages.map((msg) => (
                      <TableRow key={msg.id}>
                        <TableCell>{msg.event}</TableCell>
                        <TableCell>{msg.message}</TableCell>
                        <TableCell>
                          <Chip 
                            label={msg.type}
                            color={msg.type === 'broadcast' ? 'primary' : 'default'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{new Date(msg.sent).toLocaleString()}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Chip
                              icon={<GroupIcon />}
                              label={`${msg.recipients} recipients`}
                              size="small"
                              color="primary"
                            />
                            {msg.delivered === msg.recipients ? (
                              <Tooltip title="All messages delivered">
                                <CheckCircleIcon color="success" />
                              </Tooltip>
                            ) : (
                              <Tooltip title={`${msg.failed} failed deliveries`}>
                                <ErrorIcon color="error" />
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="View Details">
                            <IconButton size="small">
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MessageHistory; 