import React from 'react';
import { Box, Grid, Card, CardContent, Typography, Paper } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

function Dashboard() {
  const stats = [
    {
      title: 'Total Events',
      value: '24',
      icon: <EventIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
    },
    {
      title: 'Total Attendees',
      value: '1.2k',
      icon: <PeopleIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
    },
    {
      title: 'Check-ins Today',
      value: '156',
      icon: <TrendingUpIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
    },
  ];

  const upcomingEvents = [
    {
      name: 'Chamber Networking Breakfast',
      date: '2024-03-15',
      attendees: 45,
      status: 'Upcoming'
    },
    {
      name: 'Business After Hours',
      date: '2024-03-20',
      attendees: 75,
      status: 'Open'
    },
    {
      name: 'Annual Chamber Gala',
      date: '2024-04-01',
      attendees: 200,
      status: 'Planning'
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        {stats.map((stat, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Paper elevation={2} sx={{ height: '100%' }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {stat.icon}
                    <Typography variant="h6" sx={{ ml: 2 }}>
                      {stat.title}
                    </Typography>
                  </Box>
                  <Typography variant="h3" component="div">
                    {stat.value}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={8}>
          <Paper elevation={2}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <CalendarMonthIcon sx={{ mr: 1 }} />
                  Upcoming Events
                </Typography>
                {upcomingEvents.map((event, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      mt: 2, 
                      p: 2, 
                      borderRadius: 1,
                      bgcolor: 'background.default',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {event.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {new Date(event.date).toLocaleDateString()} • {event.attendees} registered
                      </Typography>
                    </Box>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        px: 1.5, 
                        py: 0.5, 
                        borderRadius: 1,
                        bgcolor: event.status === 'Upcoming' ? 'success.light' : 
                                event.status === 'Open' ? 'info.light' : 'warning.light',
                        color: event.status === 'Upcoming' ? 'success.dark' : 
                               event.status === 'Open' ? 'info.dark' : 'warning.dark'
                      }}
                    >
                      {event.status}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={2}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Quick Stats
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    • Most popular event: Annual Chamber Gala (200 attendees)
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    • Average attendance rate: 85%
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    • Events this month: 8
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    • New registrations today: 34
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard; 