import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  Button,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';

function Events() {
  const [openModal, setOpenModal] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    date: '',
    time: '',
    location: '',
    capacity: '',
    description: ''
  });
  const [quickEvent, setQuickEvent] = useState({
    title: '',
    date: '',
    location: '',
    description: '',
    capacity: 0
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewEvent({
      title: '',
      date: '',
      time: '',
      location: '',
      capacity: '',
      description: ''
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEvent(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      const eventDateTime = new Date(`${newEvent.date}T${newEvent.time}`);
      
      const eventData = {
        title: newEvent.title,
        description: newEvent.description || 'No description provided',
        date: eventDateTime.toISOString(),
        location: newEvent.location,
        capacity: Number(newEvent.capacity) || 0
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create event');
      }

      const createdEvent = await response.json();
      setSuccessMessage(`Event "${createdEvent.title}" created successfully!`);
      handleCloseModal();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleQuickAddChange = (e) => {
    const { name, value } = e.target;
    setQuickEvent(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleQuickAdd = async () => {
    try {
      const eventData = {
        title: quickEvent.title,
        description: 'Quick event',
        date: new Date(quickEvent.date).toISOString(),
        location: quickEvent.location,
        capacity: 0
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/events`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create quick event');
      }

      const createdEvent = await response.json();
      setSuccessMessage(`Quick Event "${createdEvent.title}" created successfully!`);
      setQuickEvent({
        title: '',
        date: '',
        location: '',
        description: '',
        capacity: 0
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const sampleEvents = [
    {
      id: 1,
      name: 'Company Holiday Party',
      date: '2024-12-20',
      location: 'Main Conference Room',
      attendees: 45
    },
    {
      id: 2,
      name: 'Team Building Workshop',
      date: '2024-06-15',
      location: 'Central Park',
      attendees: 28
    },
    {
      id: 3,
      name: 'Product Launch',
      date: '2024-03-30',
      location: 'Convention Center',
      attendees: 150
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 4 }}>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleOpenModal}
        >
          New Event
        </Button>
      </Box>

      {/* New Event Modal */}
      <Dialog 
        open={openModal} 
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Event</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Event Name"
                name="title"
                value={newEvent.title}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Date"
                name="date"
                type="date"
                value={newEvent.date}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Time"
                name="time"
                type="time"
                value={newEvent.time}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={newEvent.location}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Capacity"
                name="capacity"
                type="number"
                value={newEvent.capacity}
                onChange={handleInputChange}
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={newEvent.description}
                onChange={handleInputChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={!newEvent.title || !newEvent.date || !newEvent.time || !newEvent.location}
          >
            Create Event
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success/error messages */}
      <Snackbar open={!!successMessage || !!errorMessage} autoHideDuration={6000} onClose={() => { setSuccessMessage(''); setErrorMessage(''); }}>
        <Alert onClose={() => { setSuccessMessage(''); setErrorMessage(''); }} severity={successMessage ? 'success' : 'error'}>
          {successMessage || errorMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            Upcoming Events
          </Typography>
          {sampleEvents.map((event) => (
            <Paper 
              key={event.id} 
              elevation={2} 
              sx={{ mb: 2, '&:hover': { boxShadow: 6 } }}
            >
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <EventIcon sx={{ mr: 1, color: 'primary.main' }} />
                    <Typography variant="h6">
                      {event.name}
                    </Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography color="text.secondary">
                        Date: {new Date(event.date).toLocaleDateString()}
                      </Typography>
                      <Typography color="text.secondary">
                        Location: {event.location}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ 
                      display: 'flex', 
                      justifyContent: 'flex-end',
                      alignItems: 'center' 
                    }}>
                      <Typography color="text.secondary">
                        {event.attendees} Attendees
                      </Typography>
                      <Button 
                        variant="outlined" 
                        size="small" 
                        sx={{ ml: 2 }}
                        onClick={() => console.log('View event', event.id)}
                      >
                        View Details
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          ))}
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Quick Add Event
              </Typography>
              <TextField
                fullWidth
                label="Event Name"
                name="title"
                value={quickEvent.title}
                onChange={handleQuickAddChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Date"
                name="date"
                type="date"
                value={quickEvent.date}
                onChange={handleQuickAddChange}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={quickEvent.location}
                onChange={handleQuickAddChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Description"
                name="description"
                value={quickEvent.description}
                onChange={handleQuickAddChange}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Capacity"
                name="capacity"
                type="number"
                value={quickEvent.capacity}
                onChange={handleQuickAddChange}
                InputProps={{ inputProps: { min: 0 } }}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 2 }}
                startIcon={<AddIcon />}
                onClick={handleQuickAdd}
                disabled={!quickEvent.title || !quickEvent.date || !quickEvent.location}
              >
                Create Event
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Events; 