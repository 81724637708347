import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Snackbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import QrCodeIcon from '@mui/icons-material/QrCode';
import VisibilityIcon from '@mui/icons-material/Visibility';

const formatPreviewText = (text, type) => {
  if (type === 'sms') {
    const [phoneNumber, message] = text.split(':').map(part => part.trim());
    return `sms:${phoneNumber}:${message || ''}`;
  } else if (type === 'url' && !text.startsWith('http')) {
    return `https://${text}`;
  }
  return text;
};

const QRCodeManager = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedQR, setSelectedQR] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Move qrCodes inside useMemo
  const qrCodes = useMemo(() => [
    {
      id: 1,
      eventName: 'Tech Conference 2024',
      created: '2024-02-15',
      scans: 45,
      status: 'active',
      text: '555-123-4567:Hello from Tech Conference!',
      type: 'sms'
    },
    {
      id: 2,
      eventName: 'Networking Mixer',
      created: '2024-02-10',
      scans: 28,
      status: 'expired',
      text: 'example.com',
      type: 'url'
    }
  ], []); // Empty dependency array since this is static data

  // Filter QR codes based on search term
  const filteredQRCodes = useMemo(() => {
    return qrCodes.filter(qr => 
      qr.eventName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      qr.created.toLowerCase().includes(searchTerm.toLowerCase()) ||
      qr.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [qrCodes, searchTerm]);

  const handlePreview = (qrCode) => {
    const formattedQR = {
      ...qrCode,
      text: formatPreviewText(qrCode.text, qrCode.type)
    };
    setSelectedQR(formattedQR);
    setOpenPreview(true);
  };

  const handleEdit = (qrCode) => {
    setSelectedQR(qrCode);
    setOpenEdit(true);
  };

  const handleDeleteClick = (qrCode) => {
    setDeleteTarget(qrCode);
    setOpenDelete(true);
  };

  const handleDownload = (qrCode) => {
    // Implement download functionality
    console.log('Download QR code:', qrCode);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setSelectedQR(null);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectedQR(null);
  };

  const handleConfirmDelete = () => {
    // Here you would typically make an API call to delete the QR code
    console.log('Deleting QR code:', deleteTarget);
    
    // Show success message
    setSnackbar({
      open: true,
      message: `QR code "${deleteTarget.eventName}" has been deleted`,
      severity: 'success'
    });
    
    setOpenDelete(false);
    setDeleteTarget(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDeleteTarget(null);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <TextField
                  placeholder="Search QR codes..."
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  startIcon={<QrCodeIcon />}
                  onClick={() => console.log('Generate new QR code')}
                >
                  Generate New QR Code
                </Button>
              </Box>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Event</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Usage</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredQRCodes.map((qrCode) => (
                      <TableRow key={qrCode.id}>
                        <TableCell>{qrCode.eventName}</TableCell>
                        <TableCell>
                          <Chip
                            label={qrCode.status}
                            color={qrCode.status === 'active' ? 'primary' : 'default'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>{qrCode.created}</TableCell>
                        <TableCell>{qrCode.scans} scans</TableCell>
                        <TableCell>
                          <Chip
                            label={qrCode.status}
                            color={qrCode.status === 'active' ? 'success' : 'default'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton size="small" onClick={() => handlePreview(qrCode)}>
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton size="small" onClick={() => handleEdit(qrCode)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton size="small" onClick={() => handleDownload(qrCode)}>
                            <DownloadIcon />
                          </IconButton>
                          <IconButton size="small" color="error" onClick={() => handleDeleteClick(qrCode)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {filteredQRCodes.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <Typography color="text.secondary">
                            No QR codes found matching your search
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Preview Dialog */}
      <Dialog open={openPreview} onClose={handleClosePreview}>
        <DialogTitle>QR Code Preview</DialogTitle>
        <DialogContent>
          {selectedQR && (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              <QrCodeIcon sx={{ fontSize: 120, mb: 2 }} />
              <Typography variant="h6" gutterBottom>
                {selectedQR.eventName}
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Created: {selectedQR.created}
              </Typography>
              <Typography color="text.secondary">
                Content: {selectedQR.text}
              </Typography>
              <Typography color="text.secondary">
                Usage: {selectedQR.scans} scans
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview}>Close</Button>
          <Button variant="contained" startIcon={<DownloadIcon />}>
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={openEdit} onClose={handleCloseEdit}>
        <DialogTitle>Edit QR Code</DialogTitle>
        <DialogContent>
          {selectedQR && (
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Name"
                defaultValue={selectedQR.eventName}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Created"
                defaultValue={selectedQR.created}
                margin="normal"
              />
              <TextField
                select
                fullWidth
                label="Status"
                defaultValue={selectedQR.status}
                margin="normal"
              >
                <option value="active">Active</option>
                <option value="expired">Expired</option>
              </TextField>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button variant="contained" onClick={handleCloseEdit}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the QR code "{deleteTarget?.eventName}"? 
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button 
            onClick={handleConfirmDelete} 
            variant="contained" 
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default QRCodeManager; 