import React, { useState } from 'react';
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function CheckIn() {
  const [searchTerm, setSearchTerm] = useState('');
  
  const recentCheckins = [
    {
      name: 'John Smith',
      event: 'Chamber Networking Breakfast',
      time: '9:15 AM',
      status: 'Checked In'
    },
    {
      name: 'Sarah Johnson',
      event: 'Chamber Networking Breakfast',
      time: '9:12 AM',
      status: 'Checked In'
    },
    {
      name: 'Mike Wilson',
      event: 'Chamber Networking Breakfast',
      time: '9:08 AM',
      status: 'Guest'
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                <QrCodeScannerIcon sx={{ mr: 1 }} />
                Quick Check In
              </Typography>
              <TextField
                fullWidth
                label="Search by name or phone number"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Button
                fullWidth
                variant="contained"
                startIcon={<CheckCircleIcon />}
                sx={{ mt: 2 }}
              >
                Check In
              </Button>
              <Divider sx={{ my: 3 }} />
              <Typography variant="subtitle1" gutterBottom>
                Or scan QR code
              </Typography>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<QrCodeScannerIcon />}
              >
                Open Scanner
              </Button>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={2}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recent Check-ins
                </Typography>
                <List>
                  {recentCheckins.map((checkin, index) => (
                    <ListItem
                      key={index}
                      divider={index !== recentCheckins.length - 1}
                      sx={{ py: 2 }}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={checkin.name}
                        secondary={
                          <React.Fragment>
                            <Typography component="span" variant="body2">
                              {checkin.event}
                            </Typography>
                            <br />
                            {checkin.time}
                          </React.Fragment>
                        }
                      />
                      <Chip
                        label={checkin.status}
                        color={checkin.status === 'Guest' ? 'warning' : 'success'}
                        size="small"
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CheckIn; 